import React, { useEffect } from 'react'
import { useLineServiceComplete } from '../const/graphql/use-line-services-complete'
import { get } from '../services/service-general/service-general'
import { getDataIb } from '../services/service-indexDB/service-indexDB'
import { connect } from 'react-redux'
import './index.scss'
import Seo from '../components/internal-pages/common/seo/seo'

export function IndexComponent(props) {
  useEffect(() => {
    const isBrowser = typeof window !== 'undefined'
    const prefix =
      process.env.PATH_PREFIX === '' ? '/' : '/' + process.env.PATH_PREFIX + '/'

    if (isBrowser) {
      const typeUserLocalStorage = Number(
        window.localStorage.getItem('type-user')
      )
      window.location.href = `${prefix}${
        typeUserLocalStorage === 2 ? 'empresas' : 'personas'
      }`
    }
  }, [])

  return (
    <Seo
      pageContext={{
        title: 'Protección: Pensiones, Cesantías, Ahorro e Inversión'
      }}
    />
  )
}

function mapStateToProps(state) {
  return {
    user: state.youcantmiss,
    nameUser: state.welcome,
    perfilamiento: state.home,
    statusMenu: state.menu.status,
    statusTourguide: state.tourguide.status,
    accessibility: state.accesibility
  }
}
IndexComponent = connect(mapStateToProps)(IndexComponent)

export const IndexPage = props => {
  const data = useLineServiceComplete()
  return (
    <div className="container-page-home">
      <IndexComponent
        {...props}
        data={data}
        getF={get}
        getInfoDb={getDataIb}
        showTest={true}
      />
    </div>
  )
}

export default IndexPage
